import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Experience" />

    <div>
      <h1 className="text-4xl text-black mb-4">
        Exper<span className="text-blue-600">ience</span>
      </h1>

      <h2>Qualifications</h2>
      <ul className="list-disc ml-12">
        <li>Bachelor Arts: BA (Hons), Social Studies.</li>
        <li>
          Post Graduate Diploma: PG DIP, Professional Counselling and
          Psychotherapy.
        </li>
        <li>
          University Certificate Working therapeutically with children and
          adolescents.
        </li>
        <li>Neuro Linguistic Programming (NLP) Diploma</li>
        <li>BACP Accreditation</li>
        <li>Diploma Supervision</li>
        <li>Certificate Working with sexual abuse </li>
        <li>Certificate Working with couples</li>
        <li>I.E.M.T practitioner</li>
      </ul>

      <h2 className="text-2xl mt-8 mb-2">Experience </h2>
      <p>
        My week consists of seeing clients in private practice . I also work as
        an affiliate counsellor for Employment Assistance Programmes (EAP’s) and
        am a supervisor for student and qualified counsellors.
      </p>
      <p>
        My previous experience included working at a local Women’s Centre where
        I saw people with a variety of issues including Anxiety, Depression,
        Bereavement and Abuse.
      </p>
      <p>I have worked in schools and college with children and Adolescents</p>
      <p>
        I have volunteered for the local Multiple Sclerosis in a variety of
        roles, I set up a counselling service for those affected by MS. This
        service is for people with MS themselves, their family, friends or
        carers.
      </p>
      <p>
        As a Accredited member of the BACP I undertake CPD ( continual
        professional development) on a regular basis.
      </p>
    </div>
  </Layout>
)

export default IndexPage
